import React, { useState } from "react";
import './App.css';
import Login from './Pages/Login/Login';
import Main from  './Pages/Main/Main';
import { Routes, Route } from 'react-router-dom';
import { RequireAuth } from "react-auth-kit";


function App() {

  return (
    <Routes>
      <Route path="/" element={<RequireAuth loginPath="/login"> <Main/> </RequireAuth> } />
      <Route path="/Login" element={<Login />} />
    </Routes>
);
}

export default App;
