import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSignIn } from "react-auth-kit";
import "./Login.css"
import { API_URL } from "../../Variables"


function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const signIn = useSignIn()

  const handleSubmit = async (event) => {
    console.log("Clicked")
    event.preventDefault();
    setError(false)
    let data = { 'username': username, 'password': password }
    try {
      console.log(API_URL)
      const response = await fetch(`${API_URL}/api/credentials/login`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: { 'Content-Type': 'application/json' },
      });
      const jsonRes = await response.json();
      console.log(jsonRes);
      if (response.status == 200) {
        signIn({
          token: jsonRes.Token,
          tokenType: "Bearer",
          expiresIn: 1440,
          authState: {
            'username': username,
            'password': password
          }
        })
        setError(false);
        navigate('/');
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  }

  return (
    <div>
      <div className="py-5 text-center main" >
        <div className="container">
          <div className="row mt-4">
            <div className="mx-auto col-md-6">
              <div className="bg-white formSection mt-2">
                <div className="row my-5">
                  <div className=" col-md-12 col-12 p-5">
                    <h3 className="mb-4 display-4">Login</h3>
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <input
                          type="text"
                          placeholder="Username"
                          id="username"
                          className="form-control"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </div>
                      <div className="form-group mb-6">
                        <input
                          type="password"
                          placeholder="Password"
                          id="password"
                          value={password}
                          className="form-control"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <button type="submit" className="myButt  btn-block my-3 py-2" onClick={handleSubmit}>login</button>
                      {error ? <div className="yellowColor mt-1 ">Username or password is Incorrect</div> : ""}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
