import React from 'react'
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Textarea from '@mui/joy/Textarea';
import { Cookies } from 'react-cookie';
import { API_URL } from "../../Variables"
import CopyToClipboard from 'react-copy-to-clipboard';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  maxHeight:500,
  bgcolor: 'background.paper',
  overflowWrap: 'break-word',
  boxShadow: 24,
  p: 5,
  overflowY: "auto",
};

const CodeModal = React.memo((props) => {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const cookies = new Cookies();

  const handleUpdateTheQuery = async () => {
    try {
      const response = await fetch(`${API_URL}/api/v1/UpdateUserQuery/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Token': cookies.get('_auth')

        },
        body: JSON.stringify({
          "itemData": {
            "id": props.queryID,
            "query": props.createdCode,
            "isQueryValid": true
          }
        })
      });
      props.setIsCodeReturned(false)
      const data = await response.json();
      return { status: response.status, data };
    } catch (error) {
      return { status: 500, data: error };
    }

  }

  return (
    <div >
      <Button onClick={handleOpen} className=" py-3 px-1 bg-dark"><i className="fa fa-code fa-lg"></i></Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style} >
            <Typography id="transition-modal-title " variant="h6" component="h2" className='mv-5'>
              SQL QUERY <i className="fa fa-code"></i>
            </Typography>
            <div className='my-2'></div>
            <code>
              <Textarea
                color="neutral"
                disabled={false}
                minRows={2}
                size="lg"
                variant="outlined"
                defaultValue={props.createdCode}
                onChange={(event) => props.setCodeReturned(event.target.value)}
              />
            </code>
            <div className='d-flex justify-content-end mt-3'>
            <CopyToClipboard text={props.createdCode}>
              <button type="button" className='btn btn-secondary'>
                Copy to clipboard
              </button>
            </CopyToClipboard>
              <button
                type="button"
                className='btn btn-success ml-2'
                onClick={handleUpdateTheQuery}
              >
                Save as Valid
              </button>

            </div>

          </Box>
        </Fade>
      </Modal>
    </div>
  );
})

export default CodeModal;
