import './Main.css';
import './Loader.css';
import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useSignOut } from 'react-auth-kit';
import { Cookies } from 'react-cookie';
import { ReactComponent as ReactLogo } from './logout.svg';
import Table from './Table'
import CodeModal from './CodeModal';
import { API_URL } from "../../Variables"


function Main() {
  const [inputText, setInputText] = useState("")
  const [tableData, setTableData] = useState("")
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("There is some errors, please try again")
  const [currentSystemState, setCurrentSystemState] = useState(0)
  const [codeReturned, setCodeReturned] = useState("")
  const [isCodeReturned, setIsCodeReturned] = useState(false)
  const [queryID, setQueryID] = useState(null)

  const logOut = useSignOut()
  const navigate = useNavigate()
  const cookies = new Cookies();

  async function handleLogOut() {
    logOut()
    navigate('/login')
  }

  async function OpenAIAPI(inputText) {
    const response = await fetch(`${API_URL}/api/v1/NLToData/OpenAI`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': cookies.get('_auth')
      },
      body: JSON.stringify({ "inputData": inputText })
    })


    if (response.ok) {
      let Query = await response.json()
      setQueryID(Query.SavedQueryID.data)
      setCodeReturned(Query.OpenAIResponed)
      setIsCodeReturned(true)
      return Query.OpenAIResponed
    }
    else {
      setCurrentSystemState(0)
      setMessage("Error On Connecting OpenAI")
      return response
    }
  }

  async function GoogleCloudAPI(SqlQuery) {
    const response = await fetch(`${API_URL}/api/v1/NLToData/GoogleCloud`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Token': cookies.get('_auth')
      },
      body: JSON.stringify({ "inputData": SqlQuery })
    })
    let DataReturned = await response.json()

    if (response.ok) {
      if (DataReturned.length === 0) {
        setMessage("No Matched Data Found")
        setCurrentSystemState(0)
      }
      else {

        console.log("Im in level 2")
        console.log(DataReturned)
        setShowMessage(false)
        setTableData(DataReturned || "")
        setCurrentSystemState(2)
      }
    }
    else {
      setCurrentSystemState(0)
      setMessage("Error In The Query Or The Database")
    }
  }

  async function handleSubmit(event) {
    event.preventDefault()
    setShowMessage(true)
    setIsCodeReturned(false)
    if (inputText.length > 0) {
      try {
        setMessage("Please Wait Until We Process Your Request")
        setCurrentSystemState(1)
        let OpenAIRes = await OpenAIAPI(inputText)
        if (typeof OpenAIRes === "string" && OpenAIRes != "") await GoogleCloudAPI(OpenAIRes)
         
      } catch (err) {
        //Check Here the Login Cookie to refresh
        setMessage("Error In The Server, Please Contact The Developer")
        setCurrentSystemState(0)
        console.log(err)
      }
    }
    else { setMessage("Please Enter a valid input") }
  }

  return (
    <div className='container my-3'>
      <div className='d-flex justify-content-end'>
        <div className="btn logoutButt px-3 py-1" onClick={handleLogOut}>
          <ReactLogo className='logoutSVG mx-1' />
          LOGOUT
        </div>
      </div>
      <div className='my-5 row'>
        <div className='mx-auto'>
          <h1 className='d-flex justify-content-center'>Turn Any Question to <span className='yellowColor mx-1'> Information</span></h1>
          <h5 className='d-flex justify-content-center'>Harness the Force of Intelligent  <span className='yellowColor mx-1'> Property Discovery </span></h5>
        </div>
        
        <div className="search-container my-3 col-md-8 offset-md-2" >
          <form>
            <input type="text"
              placeholder="Search..."
              className="search"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}

            />
            {currentSystemState == 1 ? <div className="lds-hourglass"></div> :
              <div className='d-flex'>
                <button type="submit" onClick={handleSubmit}><i className="fa fa-search mx-1"></i></button>
              </div>
            }
            {isCodeReturned ? <CodeModal createdCode={codeReturned} setCodeReturned={setCodeReturned} queryID={queryID} setIsCodeReturned={setIsCodeReturned} /> : ""}

          </form>
        </div>
        <div className='col-1'></div>
        {showMessage ? <div className='mx-auto'><p className="my-3 yellowColor">{message}</p> </div> : ""}
        {currentSystemState == 2 ?
          <Table className="my-2" data={tableData} />
          : ""}

      </div>
    </div>
  )
}

export default Main