import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { CSVLink } from "react-csv";

function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

const DataGridDemo = React.memo((props) => {
  let data = props.data
  let TableHeads = Object.keys(data[0])
  const TableHeadCount = TableHeads.length
  let HeadForCSV=[];

  TableHeads =TableHeads.map((itemHead,index)=>{
  HeadForCSV[index]={label:itemHead, key:itemHead}

    if (TableHeadCount < 7) return {field: itemHead, headerName: itemHead, flex: 1 }
    else return {field: itemHead, headerName: itemHead, width: 200 }

  })
  let TableRows = data

  
  return (
    <Box sx={{ height: 400, width: '100%' }}>
        <CSVLink headers={HeadForCSV} data={TableRows} filename={"MyData.csv"} className="y-5" > <i className='fa fa-cloud-download fa-lg'></i> Import  </CSVLink>
      <DataGrid
        rows={TableRows}
        columns={TableHeads}
        getRowId={() =>  generateRandom()}
        pageSize={5}
        rowsPerPageOptions={[5]}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  );
})

export default DataGridDemo;
